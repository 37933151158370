/* Credits to https://codesandbox.io/s/github/pmndrs/use-gesture/tree/main/demo/src/sandboxes/action-sheet?file=/src/App.jsx */

.actionBtn {
  position: absolute;
  z-index: 2000;
  bottom: 35px;
  right: 30px;
  height: 48px;
  width: 48px;
  border-radius: 24px;
  background: var(--purple);
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionBtn:after {
  content: " ";
  display: block;
  background: #fff;
  height: 20%;
  width: 20%;
  border-radius: 50%;
}

.bg {
  width: 100%;
}

.bg > img {
  width: 100%;
  margin: 0;
  display: block;
}

.sheet {
  z-index: 50000;
  position: fixed;
  left: 2vw;
  height: calc(100vh + 100px);
  width: 96vw;
  border-radius: 12px 12px 0px;
  background: #fff;
  touch-action: none;
  color: black;
  font-size: 0.9rem;
  font-weight: bold;
}

.sheet > div {
  height: 60px;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  text-transform: capitalize;
}
