.looser {
  background-color: grey;
  display: grid;
  grid-template-areas: "layer";
  place-items: center;
  animation: rotate 1s linear infinite;
  position: relative;
}

.info_looser {
  position: absolute;
  color: var(--white);
  font-weight: 700;
  font-size: 5.1rem;
}

.point_looser {
  position: relative;
  grid-area: layer;

  font-size: 1.6rem;
  color: var(--white);
  border-radius: 50%;

  --radius: 74px;

  --d: calc(var(--i) / 6);

  --r-offset: -0.25turn;

  --r-amount: 1turn;

  --r: calc((var(--r-amount) * var(--d)) + var(--r-offset));

  transform: rotate(var(--r)) translate(var(--radius)) rotateZ(90deg);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}

/* Ipdas + Mobile*/
@media only screen and (max-width: 768px) {
  .point_looser {
    --radius: 35px;
    width: 10px;
    height: 10px;
    font-size: 0.8rem;
  }

  .info_looser {
    font-size: 2.3rem;
  }
}
