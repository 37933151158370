.ReactModal__Content {
  padding: 0px !important;
  margin: -1px;
  z-index: 20;
}

.modal__menu__topbar {
  background: var(--top-bar);
  margin-top: -1px;
  height: 0.8rem;
  width: 100%;
  box-sizing: border-box;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.modal_content {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #150c21;
}

.modal__menu__newGame {
  border-bottom: 1px solid gray;
  border-top: 1px solid gray;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2.5rem;
}

.modal__menu__newGame form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#username {
  line-height: 2;
  padding: 1.5rem;
  border: 1px solid #ccc;
  border-radius: var(--default-border-radius);
  margin-bottom: 1rem;
  width: 18rem;
  box-sizing: border-box;
  color: #150c21;
  font-size: 15px;
}

#username,
#username::-webkit-input-placeholder {
  line-height: 2;
  color: #150c21;
  font-weight: bold;
}

.modal__menu__dismiss {
  margin-top: 1rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  width: 100%;
  display: flex;
}

.modal__menu__copy {
  margin-top: 1rem;
  padding-bottom: 1rem;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  border-bottom: 1px solid gray;
}

.warn {
  color: red;
}
