.menu_info_container {
  color: var(--white);
  position: absolute;
  z-index: 2;
  text-align: center;
  width: 100%;
  bottom: 8%;
  user-select: none;
}

.cursor_wrapper {
  cursor: grab;
  position: absolute;
  touch-action: none;
  user-select: none;
  transform-origin: bottom left;
  z-index: 1000;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cursor {
  --radius: clamp(3rem, 8vw, 6rem);
  width: calc(2 * var(--radius));
  height: calc(2 * var(--radius));
  border-radius: 50%;
  position: relative !important;
  border: var(--circle-border);
}

.cursor_result {
  border-radius: 50%;
  position: relative !important;
  border: var(--circle-border);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
}

.point_1 {
  width: 50%;
  height: 0.2rem;
  background: transparent;
  position: absolute;
  top: calc(50% - 0.1rem);
  transform-origin: right;
}

.point_2 {
  width: 50%;
  height: 0.2rem;
  background: transparent;
  position: absolute;
  top: calc(50% - 0.1rem);
  transform-origin: right;
}

.point_1:before,
.point_2:before {
  content: "";
  position: absolute;
  width: 1vmin;
  height: 1vmin;
  background: transparent;
  border-radius: 50%;
  top: -1rem;
  left: 0rem;
}

/* Ipdas + Mobile*/
@media only screen and (max-width: 768px) {
  .cursor {
    --radius: 20px !important;
    width: 80px !important;
    height: 80px !important;
  }

  .point_1:before,
  .point_2:before {
    height: 10px;
    width: 10px;
  }
}

.animationRev {
  animation: circleAnimationRev 2s linear forwards;
}

.animation {
  animation: circleAnimation 2s linear forwards;
}

.animationRev:before {
  background: var(--white);
}
.animation:before {
  background: var(--white);
}

.ownPlayer {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(112, 93, 242, 0.8);
  }
  70% {
    box-shadow: 0 0 0 2rem rgba(204, 169, 44, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes circleAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes circleAnimationRev {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}
