h1 {
  font-weight: 200;
  font-size: clamp(5rem, 6vw, 7rem);
  line-height: 1.2;
  letter-spacing: -0.03em;
  margin: 0;
}

h2 {
  font-weight: bold;
  font-size: clamp(1.5rem, 3vw, 6rem);
  line-height: 1.2;
  letter-spacing: -0.03em;
  margin: 0;
  text-align: center;
}

h3 {
  /*   font-size: clamp(1rem, 2.5vw, 1.5rem);  */
  font-size: 20px;
}

p {
  /*   font-size: clamp(0.8rem, 2vw, 1rem); */
  font-size: 15px;
}

.default-button {
  max-width: 18rem;
  min-width: 10rem;
  height: 5rem;
  margin: 3rem auto;
  color: var(--purple);
  border-radius: var(--default-border-radius);
  background-color: var(--white);
  font-size: clamp(1rem, 1vw, 1.6rem);
  font-weight: bold;
  padding: 1rem;

  border-left: solid 4px #69a09f;
  border-bottom: solid 4px #69a09f;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  box-shadow: -1px -1px 0px rgba(255, 255, 255, 0.1) inset;
  -moz-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.default-button:hover {
  border-bottom: solid 3px #69a09f;
  border-left: solid 3px #69a09f;
  border-right: solid 1px #ccc;
  border-top: solid 1px #ccc;
  cursor: pointer;
}

input {
  line-height: 2;
  height: 5rem;
  padding: 1.5rem;
  border: 1px solid #ccc;
  border-radius: var(--default-border-radius);
  margin-bottom: 1rem;
  width: 100%;
  box-sizing: border-box;
  color: #150c21;
  font-size: clamp(1rem, 1vw, 1.6rem);
  text-align: center;
}

input,
input::-webkit-input-placeholder {
  line-height: 2;
  color: #150c21;
  font-weight: bold;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}
