@media (prefers-reduced-motion: no-preference) {
  body {
    --playState: running;
  }
}

.create__contentWrapper {
  position: absolute;
  box-sizing: border-box;
  background: rgba(21, 12, 33, 0.98);
  padding: 5rem;
  top: 50%;
  left: 45%;
  width: 40%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  clip-path: polygon(-5% -3%, 101% -2%, 101% 102%, -2% 105%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  box-shadow: 0 0 3rem 1.5rem #fff, 0 0 5rem 3rem #f0f, 0 0 7rem 4.5rem #0ff;
}

.formWrapper {
  box-sizing: border-box;
}

.formWrapper--mobile form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.formWrapper form {
  margin-top: 3rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
}

.formWrapper form input {
  width: 100% !important;
}

.circle {
  --c1: var(--blue_light);
  --c2: var(--blue_dark);
  --size: max(8rem, 13vw);
  --delay: 0s;
  position: absolute;
  top: 0;
  left: 0;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background: radial-gradient(circle at 20% 70%, var(--c1), var(--c2));
  pointer-events: none;
  animation: var(--animation, drift) 12s var(--delay) linear infinite alternate;
  animation-play-state: var(--playState, paused);
  border: var(--circle-border);
  box-sizing: border-box;
}

.allow-motion .button__toggle {
  background-color: #ffffff;
}

.allow-motion .button__toggle::after {
  transform: translate3d(100%, 0, 0) scale(1.2);
  background-color: var(--buttonColor);
}

/* Circle animations */
.circle:nth-child(2) {
  --size: max(3rem, 10vw);
  --delay: 1s;
  top: 50%;
  left: 25%;
  border: var(--circle-border);
  background: transparent !important;
}

.circle:nth-child(3) {
  --size: max(3rem, 10vw);
  --delay: 3s;
  top: 10%;
  left: 50%;
  border: var(--circle-border);
  background: transparent !important;
}

.circle:nth-child(4) {
  --c1: var(--orange_light);
  --c2: var(--orange_dark);
  --animation: drift2;
  --delay: 5s;
  top: 70%;
  left: 15%;
}

.circle:nth-child(5) {
  --c1: var(--purple_light);
  --c2: var(--purple_dark);
  --animation: drift2;
  --delay: 4s;
  top: 60%;
  left: 70%;
}

.circle:nth-child(6) {
  --c1: var(--light_grey);
  --c2: var(--yellow);
  --delay: 7s;
  top: 15%;
  left: 80%;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes drift {
  50% {
    transform: translate3d(50%, 50%, 0);
  }
  100% {
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes drift2 {
  50% {
    transform: translate3d(-50%, -50%, 0);
  }
  100% {
    transform: translate3d(0, -100%, 0);
  }
}

/* MOBIlE */
.formWrapper form button,
.formWrapper--mobile form button {
  width: 50% !important;
  background-color: var(--purple) !important;
  color: var(--white);
  border: unset !important;
}

.formWrapper--mobile form button {
  width: 100% !important;
  background-color: var(--purple) !important;
  color: var(--white);
  border: unset !important;
  font-size: 1rem;
}

.formWrapper--mobile h1 {
  font-size: 50px;
  text-align: center;
  margin-bottom: 3rem;
}

.create__contentWrapper--mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: rgba(21, 12, 33, 0.98);
  padding: 5rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
