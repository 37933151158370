:root {
  --color_main: #150c21;
  --purple_light: #974859;
  --purple_dark: #512946;
  --orange_light: #f6b15b;
  --orange_dark: #d2755c;
  --blue_light: #a0e3f2;
  --blue_dark: #2fa7c2;
  --light_grey: #c4ba7c;
  --yellow: #f9f871;
  --white: #fff;
  --purple: #705df2;

  --circle-border: 3px solid #fff;

  --top-bar: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  --default-border-radius: 9px;
}

* {
  box-sizing: border-box;
}

html,
body {
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  overflow: hidden;
  touch-action: none;
}

body {
  position: fixed;
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Saira", sans-serif;
  overflow: hidden;
}

#root {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  touch-action: none;
}

.app {
  cursor: grab;
  touch-action: none;
  user-select: none;

  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(--color_main);
  color: var(--white);
}

/* Mobile */
@media only screen and (max-width: 480px) {
  body {
    font-size: 12.5%;
  }

  .Toastify__toast-body div:nth-child(2) {
    font-size: 0.9rem;
  }
}

/* Ipdas */
@media only screen and (min-width: 481px) and (max-width: 768px) {
  body {
    font-size: 40%;
  }
  .Toastify__toast-body div:nth-child(2) {
    font-size: 0.9rem;
  }
}

/* Laptops */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  body {
    font-size: 40%;
  }
  .Toastify__toast-body div:nth-child(2) {
    font-size: 0.9rem;
  }
}
/* 
Desktop */
@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  body {
    font-size: 62.5%;
  }
}

/* Full HD + */
@media only screen and (min-width: 1201px) {
  body {
    font-size: 100%;
  }
}

.test {
  position: relative;
  width: 300px;
  height: 300px;
  background: url(https://images.pexels.com/photos/1030963/pexels-photo-1030963.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500);
  background-size: cover;
  border-radius: 5px;
  box-shadow: 0px 10px 30px -5px rgb(0 0 0 / 30%);
  will-change: transform;
  border: 10px solid white;
  cursor: grab;
  touch-action: none;
  user-select: none;
  -webkit-user-select: none;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 22px;
  padding: 20px;
  text-align: center;
  color: #ffffffaa;
}
