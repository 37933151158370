.winner {
  display: grid;
  grid-template-areas: "layer";
  place-items: center;
  animation: rotate 1s linear infinite;
  position: relative;
}

.info_winner {
  position: absolute;
  top: 25%;
  left: 32%;

  font-size: 3rem;
  background-color: transparent !important;
}

.point_winner {
  position: relative;
  grid-area: layer;
  width: 1.2rem;
  height: 1.2rem;

  border-radius: 50%;

  background-image: linear-gradient(43deg, #4158d0 0%, #ffcc70 100%);

  --radius: 74px;

  --d: calc(var(--i) / 6);

  --r-offset: -0.25turn;

  --r-amount: 1turn;

  --r: calc((var(--r-amount) * var(--d)) + var(--r-offset));

  transform: rotate(var(--r)) translate(var(--radius));
}

/* Ipdas + Mobile*/
@media only screen and (max-width: 768px) {
  .point_winner {
    --radius: 32px;
    width: 10px;
    height: 10px;
  }

  .info_winner {
    position: unset;
    top: unset;
    left: unset;
    font-size: 2.3rem;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}
