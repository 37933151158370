.gameEnded {
  position: absolute;
  top: 0;
  left: 0;
  width: 25vw;
  height: 25vw;
  background-color: goldenrod;
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  justify-content: center;
  align-items: center;
  color: var(--white);
  border-radius: 50%;
  margin: 0 auto;
  text-align: center;
}

.gameEnded span:nth-child(1) {
  font-size: medium;
}

.gameEnded span:nth-child(2) {
  font-size: small;
}

/* Ipdas + Mobile*/
@media only screen and (max-width: 768px) {
  .gameEnded {
    width: 45vw;
    height: 45vw;
    padding-top: 1rem;
  }
}
